.services {
    &__content {
        row-gap: 2.5rem;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    &__item {
        text-align: center;
    }
    &__img {
        width: 64px;
        height: 64px;
        fill: var(--first-color);
        margin-bottom: var(--m-2);
    }
    &__title {
        font-size: var(--h3-font-size);
        color: var(--title-color);
        margin-bottom: var(--m-1);
    }
    &__description {
        padding: 0 1.5rem;
    }
    @media screen and (min-width: 576px) {}
    @media screen and (min-width: 768px) {
        &__container {
            margin-top: var(--m-6);
        }
    }
    @media screen and (min-width: 960px) {}
}
