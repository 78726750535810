.code {
	&__img-container {
		height: 100%;
		cursor: pointer;
		overflow: hidden;
		margin-bottom: var(--m-1);
		img {
			height: 130px;
			object-fit: cover;
			border-radius: .3rem;
		}
		.title {
			color: var(--first-color);
			font-size: 1rem;
			font-weight: 400;
		}
	}
	&__details {
		font-size: 1.2rem;
		color: var(--text-color);
		margin-right: var(--m-1);
	}
	@media screen and (min-width: 576px) {}
	@media screen and (min-width: 768px) {
		&__img-container {
			height: 100%;
			img {
				height: 200px;
			}
		}
		&__img-content {
			display: block;
			padding: .5rem 0;
			height: 100%;
		}
	}
	@media screen and (min-width: 960px) {}
}

@supports (display: grid) {
  .code .grid {
    grid-gap: 1rem;
  }
}
