.nav {
	max-width: 71.25rem;
	height: var(--header-height);
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__item {
		margin-bottom: var(--m-1);
	}
	&__link, &__logo, &__toggle {
		color: var(--text-color);
		font-weight: var(--font-medium);
	}
	&__logo svg {
		height: 30px;
		.a{fill:var(--first-color);transition: .3s;}
		.b{fill:url(#a);}
		.c{fill:url(#b);}
	}
	&__logo svg:hover {
		.a{
			fill:var(--first-color-alt);
			transition: .3s;
		}
	}
	&__link {
		transition: .3s;
		&:hover {
			color: var(--first-color);
		}
	}
	&__toggle {
    font-size: 1.2rem;
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
	}
	@media screen and (min-width: 576px) {}
	@media screen and (min-width: 768px) {
		& {
			height: calc(var(--header-height) + 1.5rem);
		}
		&__list {
			display: flex;
		}
		&__item {
			margin-left: var(--m-4);
			margin-bottom: 0;
		}
		&__toggle {
			display: none;
		}
		&__logo svg {
			height: 50px;
		}
	}
	@media screen and (min-width: 960px) {}
}


@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    padding: 1.2rem 0 1rem;
    text-align: center;
    background-color: var(--body-color);
    transition: .3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .2);
    border-radius: 0 0 .5rem .5rem;
    z-index: var(--z-fixed);
  }
}

// Show menu
.show-menu {
  top: var(--header-height);
}

// Active link menu
.active-link {
  color: var(--first-color);
}
