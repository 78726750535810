.bd-container {
	max-width: 960px;
	width: calc(100% - 2rem);
	margin-left: var(--m-2);
	margin-right: var(--m-2);
	@media screen and (min-width: 576px) {}
	@media screen and (min-width: 768px) {}
	@media screen and (min-width: 960px) {
		& {
			margin-left: auto;
			margin-right: auto;
		}
	}
	@media screen and (min-width: 1200px) {
		& {
			max-width: 71.25rem;
		}
	}
}

.bd-grid {
	display: grid;
	gap: 1.5rem;
}
