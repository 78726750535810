.home-bg {
	position: fixed;
	opacity: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	z-index: -10;
}
