.about {
	&__data {
		text-align: left;
	}
	&__description {
		margin-bottom: var(--m-3);
	}
	&__img {
		width: 280px;
		border-radius: .5rem;
		justify-self: center;
	}
	@media screen and (min-width: 576px) {
		&__container {
			grid-template-columns: repeat(2, 1fr);
		}
		&__data, &__initial {
			text-align: initial;
		}
		&__img {
			width: 380px;
			order: -1;
		}
	}
	@media screen and (min-width: 768px) {}
	@media screen and (min-width: 960px) {
		&__container {
			column-gap: 7rem;
		}
	}
}
