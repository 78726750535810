:root {
    --header-height: 4rem;

    // Colors
    --first-color: #0c8f86;
    --first-color-alt: #13a59b;
    --title-color: #393939;
    --text-color: #021320;
    --text-color-light: #A6A6A6;
    --body-color: #fbfefd;
    --container-color: #ffffff;

		// Forms
		--input-color: var(--text-color);
		--border-color: var(--title-color);

    // Font and typography
    --body-font: 'Poppins', sans-serif;
    --biggest-font-size: 2.25rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
    --line-height: 1.6;

    // Font weight
    --font-medium: 500;
    --font-semi-bold: 600;

    // Margins
    --m-1: .5rem;
    --m-2: 1rem;
    --m-3: 1.5rem;
    --m-4: 2rem;
    --m-5: 2.5rem;
    --m-6: 3rem;

    // Z-Index
    --z-tooltip: 10;
    --z-fixed: 100;

}

@media screen and (min-width: 768px){
    :root{
        --biggest-font-size: 4rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1.05rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}
