
.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
	transition: .3s;
}

// Change background header
.scroll-header {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
	background-color: #fbfefd!important;
	.nav__link, #theme-button {
		color: rgb(78, 78, 78)!important;
	}
	.active-link {
		color: var(--first-color)!important;
	}
}
