*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: var(--line-height);
}

h1, h2, h3, p, ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
    body {
        margin: 0;
    }
}
@media screen and (min-width: 960px) {}
