.works {
	&__content {
		grid-template-columns: repeat(1, 1fr);
		justify-content: center;
		box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
		padding: 1rem 0;
	}
	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: var(--container-color);
		padding: .5rem;
		h3 {
			font-size: var(--normal-font-size);
			margin-top: var(--m-1);
		}
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
	}
	&__title, &__description {
		z-index: 2;
	}
	&__title {
		font-size: 1.2rem;
		font-weight: 600;
		letter-spacing: 1px;
		color: var(--first-color-alt);
	}
	&__description {
		font-size: var(--normal-font-size);
		margin-bottom: var(--m-1);
	}

	&__skills span {
		transition: all .35s ease;
		&:hover {
			color: #0c534d;
			// font-style: oblique;
			transition: all .35s ease;
		}
	}
	@media screen and (min-width: 576px) {}
	@media screen and (min-width: 768px) {
		&__container {
			margin-top: var(--m-0);
		}
		&__content {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 0rem;
		}
		&__item {
			padding: 1.2rem;
		}
		&__img {
			width: 100%;
		}
	}
	@media screen and (min-width: 960px) {}
}
