.section {
    padding: 4rem 0 2rem;
    &-title, &-subtitle {
        text-align: center;
    }
    &-title {
        font-size: 22px;
        color: var(--title-color);
        margin-bottom: var(--m-3);
    }
    &-subtitle {
				font-size: var(--h1-font-size);
        display: block;
        color: var(--first-color);
        font-weight: var(--font-medium);
        margin-bottom: var(--m-1);
    }
    @media screen and (min-width: 576px) {}
    @media screen and (min-width: 768px) {
        .section {
            padding-top: 8rem;
        }
    }
    @media screen and (min-width: 960px) {}
}
