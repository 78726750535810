.apps {
	&__data {
		text-align: center;
		// width: 40%;
	}
	&__description {
		margin-bottom: var(--m-1);
	}
	&__img {
		margin-top: 60px;
		width: 60%;
		justify-self: center;
	}
	@media screen and (min-width: 576px) {
		&__container {
			grid-template-columns: 35% 65%;
			align-items: center;
		}
		&__data, &__initial {
			text-align: initial;
		}
		&__img {
			width: 280px;
			order: -1;
		}
	}
	@media screen and (min-width: 768px) {}
	@media screen and (min-width: 960px) {
		&__container {
			column-gap: 1rem;
		}
	}
}
