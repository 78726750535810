.change-theme {
	position: absolute;
	right: 1.3rem;
	top: 1.6rem;
	color: var(--text-color);
	font-size: 1.1rem;
	cursor: pointer;
}

@media screen and (min-width: 768px) {
	.change-theme {
		position: initial !important;
		margin-left: var(--m-4);
	}
}

body.dark-theme {
	--title-color: #f1f3f2;
	--text-color: #c7d1cc;
	--body-color: #191a1d;
	--container-color: #1d1e20;
}
