.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: .75rem 1rem;
  border-radius: .5rem;
  transition: .3s;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button-icon {
    display: inline-flex;
    align-items: center;
    & i {
        margin-left: var(--m-2);
        font-size: 1.3rem;
    }
}
