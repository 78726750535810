.contact {
	&__container {
		text-align: center;
	}
	&__description {
		margin-bottom: var(--m-3);
	}
	&__email {
		h3 {
			font-size: 1rem;
		}
		a {
			font-size: 1.2rem;
			letter-spacing: 1.2px;
			color: var(--first-color);
		}
	}
	@media screen and (min-width: 576px) {
		&__container, &__initial {
			text-align: initial;
		}

		&__container {
			grid-template-columns: 1.5fr 1fr;
			align-items: center;
		}

		&__buttons {
			justify-self: center;
		}
	}
	@media screen and (min-width: 768px) {}
	@media screen and (min-width: 960px) {}
}


/*===== FORM =====*/
.l-form {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form {
  width: 320px;
  padding: 3rem 1rem;
  border-radius: .5rem;
	background-color: #fff;
	box-shadow:
	0 1px 2.2px rgba(0,0,0,0.02),
	0 2.3px 5.3px rgba(0,0,0,0.028),
	0 4.4px 10px rgba(0,0,0,0.035),
	0 7.8px 17.9px rgba(0,0,0,0.042),
	0 14.6px 33.4px rgba(0,0,0,0.05),
	0 35px 80px rgba(0,0,0,0.07);
	&__title {
		font-weight: 400;
		margin-bottom: var(--m-3);
	}
	&__div, &__div--textarea {
		position: relative;
		margin-bottom: var(--m-2);
	}
	&__div {
		height: 48px;
	}
	&__div--textarea {
		height: 120px;
	}
	&__input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: var(--normal-font-size);
		border: 1px solid var(--border-color);
		border-radius: .3rem;
		outline: none;
		padding: 1rem;
		background: none;
		z-index: 1;
	}
	&__label{
		position: absolute;
		left: 1rem;
		top: .75rem;
		padding: 0 .25rem;
		background-color: #fff;
		color: var(--input-color);
		font-size: var(--normal-font-size);
		transition: .2s;
	}
	&__button{
		display: block;
		margin-left: auto;
		outline: none;
		border: none;
		cursor: pointer;
	}

}

@media screen and (min-width: 768px) {
	.form {
		width: 380px;
	}
}

/*Input focus move up label*/
.form__input:focus + .form__label{
  top: -.55rem;
  left: .8rem;
  color: var(--first-color);
  font-size: var(--smaller-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus)+ .form__label{
  top: -.55rem;
  left: .8rem;
  font-size: var(--smaller-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus*/
.form__input:focus{
  border: 2px solid var(--first-color);
}
