.home{
    &__container {
        // height: calc(100vh - var(--header-height));
        height: calc(100vh + 80px);
        align-content: center;
    }
    &__title {
        font-size: var(--biggest-font-size);
        color: var(--first-color);
        margin-bottom: var(--m-1);
				margin-top: var(--m-5);
    }
		&__subdata {
			font-size: 24px;
			color: var(--title-color);
			display: block;
			margin-bottom: 0;
		}
    &__subtitle {
        font-size: var(--h1-font-size);
        color: var(--title-color);
        margin-bottom: var(--m-4);
    }
    &__description {
        font-size: var(--normal-font-size);
        color: var(--title-color);
        margin-bottom: var(--m-4);
    }
    &__img {
        width: 300px;
        justify-self: center;
    }
    @media screen and (min-width: 576px) {
        &__container {
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
        }
    }
    @media screen and (min-width: 768px) {
        &__container {
            height: 100vh;
            justify-items: center;
        }
    }
    @media screen and (min-width: 960px) {
        &__img {
            width: 500px;
        }
    }
}


.gallery {
  padding: 0 0 2rem 0;
}

#highlight {
  color: #ea8478;
  font-size: 1.25rem;
}

.img-container {
  width: 100%;
  height: 340px;
  cursor: pointer;
  overflow: hidden;
	border-radius: .5rem;
  &:hover .img-content-hover {
    display: block;
  }
	img {
		width: 100%;
		height: 340px;
		object-fit: cover;
		transform: scale(1);
		transition: all 0.3s ease-in-out;
		&:hover {
			transform: scale(1.05);
		}
	}

	.img-content-hover {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		white-space: nowrap;
		display: none;
		padding: 1rem;
		background: #fff;
		font-weight: 400;
		margin-top: 1.25rem;
		margin-left: -2rem;
		box-shadow: 1px 2px 8px rgba(0,0,0,.3);
		border-radius: .5rem;
	}
	.title {
		color: var(--first-color);
		font-size: 1.3rem;
		font-weight: 700;
	}
	.category {
		font-size: 1rem;
		color: rgb(68, 68, 68);
	}

}
.img-content {
	display: none;
}
@media screen and (max-width: 1024px) {
  img:hover {
    transform: none;
  }

  .img-container {
    height: 100%;
    &:hover .img-content-hover {
      display: none;
    }
  }

  .img-content {
    display: block;
    padding: 1rem 0;
  }
}

@supports (display: grid) {
  .gallery .grid {
    grid-gap: 1rem;
  }
}
