.hero {
	&__container {
		height: calc(40vh - var(--header-height));
		align-content: center;
	}
	&__title {
		font-size: var(--h1-font-size);
		color: var(--first-color);
		margin-bottom: var(--m-1);
	}
	&__subtitle {
		font-size: var(--h3-font-size);
		color: var(--body-color);
		margin-bottom: var(--m-1);
	}
	@media screen and (min-width: 576px) {
		&__container {
			grid-template-columns: repeat(1, 1fr);
			justify-items: left;
		}
	}
	@media screen and (min-width: 768px) {
		&__container {
			height: calc(48vh - var(--header-height));
			justify-items: left;
		}
		&__title {
			margin-top: var(--m-4);
		}
	}
	@media screen and (min-width: 960px) {}
}

.scroll-down {
  position: relative;
  width: 100%;
  height: 100%;
}
.scroll-down::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

#section05 a {
  padding-top: 0px;
	width: 100px;
	height: 100px;
}
#section05 a span {
  position: absolute;
  top: -20px;
  left: 1rem;
  width: 18px;
  height: 18px;
  margin-left: -12px;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  transform: rotate(-45deg);
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% { opacity: 1 }
	75% { opacity: 0.3 }
  100% {
    transform: rotate(-45deg) translate(-40px, 40px);
    opacity: 0;
  }
}
