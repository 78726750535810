.description {
	&__title {
		width: 90%;
		font-weight: 400;
		margin-bottom: var(--m-2);
		margin-top: var(--m-5);
		font-size: var(--normal-font-size);
	}
	&__video {
		width: 100%;
		height: 100%;
	}
	@media screen and (min-width: 768px) {
		&__title {
			width: 60%;
			font-size: var(--h2-font-size);
		}
	}
}
