.footer {
	&__container {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		row-gap: 2rem;
	}
	&__content, &__content--socials {
		text-align: center;
	}
	&__logo {
		svg {
			width: 200px;
			.a{fill:var(--first-color);transition: .3s;}
			.b{fill:url(#a);}
			.c{fill:url(#b);}
			&:hover {
				.a{
					fill:var(--first-color-alt);
					transition: .3s;
				}
			}
		}
	}
	&__socials {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	&__social {
		font-size: 1.4rem;
		color: var(--text-color);
		margin-right: var(--m-2);
		transition: .3s;
		&:last-child {
			margin-right: 0;
		}
	}
	&__links {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		height: 100%;
	}
	&__link {
		font-size: 1.2rem;
		display: block;
		color: var(--text-color);
		margin-right: var(--m-2);
		transition: .3s;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			color: var(--first-color);
		}
	}
	&__copy {
		text-align: center;
		font-size: var(--smaller-font-size);
		color: var(--text-color-light);
		margin-top: 2rem;
		padding-top: 30px;
	}
}

@media screen and (min-width: 576px) {}
@media screen and (min-width: 768px) {
	.footer {
		&__social {
			font-size: 1.8rem;
			margin-right: var(--m-3);
			&:hover {
				color: var(--first-color);
			}
		}
	}
}
@media screen and (min-width: 960px) {}
