.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .4rem .5rem;
  background-color: var(--first-color);
  border-radius: .4rem;
  z-index: var(--z-tooltip);
  transition: .3s;
  visibility: hidden;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  &__icon {
    font-size: 1.4rem;
    color: var(--body-color);
  }
}

.scrolltop:hover {
  background-color: var(--first-color-alt);
}

// Show scrolltop
.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}
